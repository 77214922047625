.react-datepicker {
  font-family: "Noto";
  box-shadow: 0 3px 9px 0 #888888;
  border: none;
}

.react-datepicker__header {
  background: #fdfdfd;
  border-bottom: none;
  padding: none;
}

.react-datepicker__month {
  background: #fdfdfd;
  margin: none;
}

.react-datepicker-popper {
  z-index: 1500;
}

.react-datepicker__day.CainthusDatePicker {
  font-size: 16px;
  background: #f8f8f8;
  color: #595959;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 1px;
}

.react-datepicker__day.react-datepicker__day--selected.CainthusDatePicker,
.react-datepicker__day--keyboard-selected.CainthusDatePicker.CainthusDatePicker__EndDate,
.react-datepicker__day--keyboard-selected.CainthusDatePicker.CainthusDatePicker__EndDate,
.react-datepicker__day.react-datepicker__day--in-range.CainthusDatePicker,
.react-datepicker__day.react-datepicker__day--in-selecting-range.CainthusDatePicker {
  background: #008389;
  color: #ffffff;
  font-weight: bold;
}

.react-datepicker__day.react-datepicker__day--today.CainthusDatePicker {
  font-weight: normal;
}

.react-datepicker__day.react-datepicker__day--outside-month.CainthusDatePicker {
  visibility: hidden;
  background: #fdfdfd;
}

.react-datepicker__day.react-datepicker__day--disabled.CainthusDatePicker {
  color: #cccccc;
  background: #fdfdfd;
}

.react-datepicker__day-names {
  margin: 0 0.4rem;
}

.react-datepicker__day-name {
  color: #595959;
  width: 40px;
  line-height: 14px;
  font-size: 16px;
  margin: 0 1px;
}
